@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Sen:wght@500;600;700&display=swap");

*,
::before,
::after {
  margin: 0;
  box-sizing: border-box;
  caret-color: transparent;
  font-family: "Sen", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #0f1113;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  background: none;
}

button:hover {
  opacity: 0.7;
}

input {
  background: none;
  outline: none;
  border: none;
}

textarea {
  background: none;
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  transition: 0.2s;
}

a:hover {
  opacity: 0.7;
}

.App {
  display: flex;
  flex-direction: column;
}

.App > svg {
  max-height: 710px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}

::-webkit-scrollbar {
  width: 0;
}
